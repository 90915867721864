<template>
  <div class="request_calculations component-box" :class="{'mini': !expand}">
    <div class="component-box__header">
      <h2 @click="expand = !expand">Расчеты ({{calculations.length}})</h2>
      <el-button size="mini" plain icon="el-icon-plus" @click="create">Новый расчет</el-button>
    </div>
    <div class="component-box__content" v-if="expand">
      <div class="list" v-if="calculations.length">
        <request-calculation
            v-for="c in calculations"
            :calculation="c"
            @needUpdate="loadCalculations"
            @needUpdateOrders="updateOrders"
        ></request-calculation>
      </div>
      <div class="empty" v-else>
        <p>Нет данных для отображения</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as calculationActions} from "@/store/modules/calculations";

import price from "@/filters/price";
import qty from "@/filters/qty";

import autocomplete from "@/api/autocomplete";

import draggable from 'vuedraggable'
import RequestCalculation from "@/components/request/RequestCalculation";

export default {
  name: "RequestCalculations",

  props: {
    requestId: {
      type: Number,
      required: true
    }
  },

  components: {
    RequestCalculation,
    draggable
  },

  filters: {
    price, qty
  },

  data() {
    return {
      newPosition: '',
      expand: false
    }
  },

  computed: {
    ...mapState({
      calculations: state => state.calculations.list,
      loading: state => state.calculations.loading,
      documentTemplates: state => state.document_templates.templates
    })
  },
  methods: {

    loadCalculations() {
      this.$store.dispatch(calculationActions.getByRequestId, this.requestId)
    },

    create(){
      this.expand = true
      this.$store.dispatch(calculationActions.create, {rid: this.requestId}).then(() => {
        this.loadCalculations()
      })
    },
    clear() {
      this.$store.dispatch(calculationActions.clear)
    },
    updateOrders(){
      this.$emit('needUpdateOrders')
    }
  },
  mounted() {
    this.loadCalculations()
  },
  watch: {
    requestId() {
      this.loadCalculations()
    }
  },
  beforeDestroy() {
    this.clear()
  }

}
</script>

