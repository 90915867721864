<template>
  <div class="item">
    <div class="preview">
      <div class="title">
        Расчет №
        <el-input class="doc_num_input" size="mini" v-model="calculation.number"
                  @change="changeCalculation(calculation)"></el-input>
      </div>
      <div class="time">
        <i class="el-icon-time"></i> Создан {{ moment(calculation.created_at).format('DD.MM.YYYY HH:mm') }}<span
          v-if="calculation.updated_at" class="last_update">,
                изменен {{ moment(calculation.updated_at).calendar() }}</span>
      </div>
      <div class="money">
        <span class="price">Сумма: <strong>{{ calculation.sum | price }}</strong> р</span>
        <span class="price">Со скидкой: <strong>{{ calculation.sum_wd  | price }}</strong> р</span>
        <span class="price">Скидка: <strong>{{ (calculation.sum - calculation.sum_wd)  | price
          }}</strong> р</span>
      </div>
    </div>
    <div class="data">
      <div class="actions">

        <el-dropdown trigger="click" @command="setDiscount" class="discount_btn" v-if="calculation.positions.length">
          <el-button type="primary" plain size="mini" icon="el-icon-discount">Скидка по документу</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{id: calculation.id, discount: 0 }">Без скидки</el-dropdown-item>
            <el-dropdown-item :command="{id: calculation.id, discount: 5 }">5%</el-dropdown-item>
            <el-dropdown-item :command="{id: calculation.id, discount: 10 }">10%</el-dropdown-item>
            <el-dropdown-item :command="{id: calculation.id, discount: 15 }">15%</el-dropdown-item>
            <el-dropdown-item :command="{id: calculation.id, discount: 20 }">20%</el-dropdown-item>
            <el-dropdown-item :command="{id: calculation.id, discount: 25 }">25%</el-dropdown-item>
            <el-dropdown-item :command="{id: calculation.id, discount: 30 }">30%</el-dropdown-item>
            <el-dropdown-item :command="{id: calculation.id, discount: 35 }">35%</el-dropdown-item>
            <el-dropdown-item :command="{id: calculation.id, discount: 40 }">40%</el-dropdown-item>
            <el-dropdown-item :command="{id: calculation.id, discount: 45 }">45%</el-dropdown-item>
            <el-dropdown-item :command="{id: calculation.id, discount: 50 }">50%</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>


        <el-button
            type="info"
            plain
            size="mini"
            icon="el-icon-copy-document"
            @click="copy(calculation)">Копировать
        </el-button>

        <el-dropdown trigger="click" @command="print" class="action_btn" style="margin: 0 0 0 10px">
          <el-button type="primary" plain size="mini" icon="el-icon-printer">PDF</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="t in documentTemplates"
                :key="t.id"
                :command="{template: t.id, type: 'calculation', id: calculation.id , pdf: 'pdf'}"
                v-if="t.for_calculations">
              {{ t.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown trigger="click" @command="print" class="action_btn" style="margin: 0 10px 0 10px">
          <el-button type="primary" plain size="mini" icon="el-icon-download">Word</el-button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="t in documentTemplates"
                :key="t.id"
                :command="{template: t.id, type: 'calculation', id: calculation.id , pdf: ''}"
                v-if="t.for_calculations">
              {{ t.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button
            type="success"
            plain
            size="mini"
            icon="el-icon-shopping-cart-full"
            @click="toOrder(calculation)">Создать заказ
        </el-button>
        <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="deleteCalculation(calculation.id)">Удалить
        </el-button>
      </div>
      <div class="positions">
        <table class="table document_edit">
          <thead>
          <tr>
            <th>#</th>
            <th></th>
            <th>Наименование</th>
            <th></th>
            <th>Кол-во</th>
            <th>Цена, р</th>
            <th>Скидка, %</th>
            <th>Сумма р</th>
            <th></th>
          </tr>
          </thead>
          <draggable tag="tbody" v-model="calculation.positions" draggable=".item"
                     @change="reorder(calculation.positions)">
            <tr v-for="(p,idx) in calculation.positions" class="item">
              <td>{{ ++idx }}</td>
              <td class="image">
                <div class="i" v-if="p.assortment.image">
                  <el-tooltip placement="right" effect="light">
                    <div slot="content">
                      <img :src="'https://online.lbsteklo.ru/images/full/' +p.assortment.image.file"
                           style="max-width: 500px; display: inline-block; vertical-align: middle;"
                      />
                    </div>
                    <img :src="'https://online.lbsteklo.ru/images/tiny/' +p.assortment.image.file"
                         style="max-width: 100%; display: inline-block; vertical-align: middle;"
                    />
                  </el-tooltip>
                </div>
              </td>
              <td>
                <span class="title">{{ p.assortment.title }}</span>
                <span class="codes">
                        <span class="code" v-if="p.assortment.code">Код: {{ p.assortment.code }}</span>
                        <span class="code" v-if="p.assortment.sku">Артикул: {{ p.assortment.sku }}</span>
                      </span>

              </td>
              <td style="width: 40px">
                <el-dropdown trigger="click" @command="setPositionPrice" class="action_btn">
                  <el-button size="mini" icon="el-icon-more" circle class="btn_more"></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{id: p.id, price: p.assortment.retail_price }">
                      Розничная цена: {{ p.assortment.retail_price | price }}р
                    </el-dropdown-item>
                    <el-dropdown-item :command="{id: p.id, price: p.assortment.wholesale_base_price }">
                      Оптовая цена: {{ p.assortment.wholesale_base_price | price }}р
                    </el-dropdown-item>
                    <el-dropdown-item :command="{id: p.id, price: p.assortment.min_price }">
                      Минимальная цена: {{ p.assortment.min_price | price }}р
                    </el-dropdown-item>
                    <el-dropdown-item divided :command="{id: p.id, price: p.assortment.buy_price }">
                      Цена закупки: {{ p.assortment.buy_price | price }}р
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
              <td style="width: 170px">
                <el-input-number
                    size="mini"
                    v-model="p.qty"
                    :precision="Number(p.assortment.uom.decimal_places)"
                    :step="1"
                    @change="updatePosition(p)"
                ></el-input-number>
                {{ p.assortment.uom.short }}
              </td>
              <td style="width: 168px">
                <el-input-number
                    size="mini"
                    v-model="p.price"
                    :precision="2"
                    :step="1"
                    @change="updatePosition(p)"
                ></el-input-number>
                р
              </td>
              <td style="width: 120px">
                <el-input-number
                    size="mini"
                    v-model="p.discount"
                    :precision="0"
                    :step="1"
                    @change="updatePosition(p)"
                    style="width: 90px;"
                ></el-input-number>
                %
              </td>
              <td class="sum">
                {{ (p.price * p.qty - ((p.price * p.qty) / 100) * p.discount) | price }} р
              </td>
              <td>
                <el-button size="mini" plain icon="el-icon-delete" class="delete_btn"
                           @click="deletePosition(p)"></el-button>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <el-autocomplete
                    v-model="newPosition"
                    :fetch-suggestions="searchAssortments"
                    size="mini"
                    placeholder="Введите наименование, код или артикул"
                    @select="addSelectedPosition"
                    :debounce="500"
                    :trigger-on-focus="false"
                    class="assortment_autocomplete"
                    popper-class="assortment_autocomplete"
                >
                  <template slot-scope="{ item }">
                    <div class="row">
                      <div class="image">
                        <img :src="'https://online.lbsteklo.ru/images/tiny/' + item.images[0].file"
                             v-if="item.images.length"
                        />
                      </div>
                      <span class="title">{{ item.title }}</span>
                      <span class="codes">
                              <span class="code" v-if="item.code">Код: {{ item.code }}</span>
                              <span class="code" v-if="item.sku">Артикул: {{ item.sku }}</span>
                            </span>

                    </div>
                  </template>
                </el-autocomplete>
              </td>
              <td>
                <el-button size="mini">Подбор</el-button>
              </td>
              <td colspan="2" style="text-align: right" class="total">
                Итого:
              </td>
              <td colspan="2" class="total">
                {{ calculation.sum | price }} р
              </td>
            </tr>
            <tr>
              <td colspan="7" style="text-align: right;" class="total">
                Итого со скидкой:
              </td>
              <td colspan="2" class="total">
                {{ calculation.sum_wd | price }} р
              </td>
            </tr>
            <tr>
              <td colspan="7" style="text-align: right;" class="total">
                Скидка:
              </td>
              <td colspan="2" class="total">
                {{ calculation.sum - calculation.sum_wd | price }} р
              </td>
            </tr>

          </draggable>
        </table>
      </div>
      <div class="comment">
        <el-input
            type="textarea"
            v-model="calculation.comment"
            placeholder="Комментарий"
            size="mini"
            :autosize="{ minRows: 2 }"
            @change="changeCalculation(calculation)"
        ></el-input>
      </div>

    </div>
  </div>
</template>

<script>
import {actionTypes as calculationActions} from "@/store/modules/calculations";
import {mapState} from 'vuex'
import price from "@/filters/price";
import qty from "@/filters/qty";

import autocomplete from "@/api/autocomplete";

import draggable from 'vuedraggable'

export default {
  name: "RequestCalculation",
  props: {
    calculation: {
      type: Object,
      required: true
    }
  },
  components: {
    draggable
  },

  filters: {
    price, qty
  },

  data() {
    return {
      newPosition: '',
      showPositions: false,
    }
  },

  computed: {
    ...mapState({
      documentTemplates: state => state.document_templates.templates
    })
  },
  methods: {
    searchAssortments(queryString, cb) {
      if (
          queryString !== undefined &&
          queryString !== null &&
          queryString.length > 2
      ) {
        autocomplete
            .searchAssortment({query: queryString})
            .then((result) => {
              if (result.data.length) {
                cb(result.data);
              } else {
                cb([]);
              }
            });
      } else {
        cb([]);
      }
    },
    addSelectedPosition(item) {
      this.$store.dispatch(calculationActions.addPosition, {cid: this.calculation.id, aid: item.id})
          .then(() => {
            this.loadCalculations()
          });
    },
    reorder(items) {
      this.$store.dispatch(calculationActions.reorder, items)
    },
    setPositionPrice(pp) {
      this.$store.dispatch(calculationActions.setPositionPrice, pp).then(() => {
        this.loadCalculations()
      })
    },
    loadCalculations(){
      this.$emit('needUpdate')
    },
    updatePosition(p) {
      this.$store.dispatch(calculationActions.updatePosition, p).then(() => {
        this.loadCalculations()
      })
    },
    deletePosition(p) {
      this.$store.dispatch(calculationActions.deletePosition, p).then(() => {
        this.loadCalculations()
      })
    },
    changeCalculation(calculation) {
      this.$store.dispatch(calculationActions.save, calculation).then(() => {

      })
    },
    copy(calculation) {
      this.$store.dispatch(calculationActions.copy, calculation).then(() => {
        this.loadCalculations()
      })
    },
    toOrder(calculation) {
      this.$store.dispatch(calculationActions.toOrder, calculation).then(() => {
        this.$emit("needUpdateOrders", this.requestId);
      })
    },
    setDiscount(c) {
      this.$store.dispatch(calculationActions.setDiscount, c).then(() => {
        this.loadCalculations()
      })
    },
    print(c) {
      window.open(
          `https://online.lbsteklo.ru/create/document/${c.type}/${c.id}/${c.template}/${c.pdf}`,
          '_blank'
      );
    },
    deleteCalculation(id) {
      this.$store.dispatch(calculationActions.delete, id).then(() => {
        this.loadCalculations()
      })
    },
  },
}
</script>
