<template>
  <div class="request_orders component-box" :class="{'mini': !expand}">
    <div class="component-box__header">
      <h2 @click="expand = !expand">Заказы ({{orders.length}})</h2>
      <el-button size="mini" plain icon="el-icon-plus" @click="create">Новый заказ</el-button>
    </div>
    <div class="component-box__content" v-if="expand">
      <div class="list" v-if="orders.length">
        <request-order
            v-for="o in orders"
            :order="o"
            @needUpdate="loadOrders"
        ></request-order>
      </div>
      <div class="empty" v-else>
        <p>Нет данных для отображения</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as orderActions} from "@/store/modules/orders";

import price from "@/filters/price";
import qty from "@/filters/qty";

import autocomplete from "@/api/autocomplete";

import draggable from 'vuedraggable'
import RequestOrder from "@/components/request/RequestOrder";

export default {
  name: "RequestOrders",

  props: {
    requestId: {
      type: Number,
      required: true
    }
  },

  components: {
    RequestOrder,
    draggable
  },

  filters: {
    price, qty
  },

  data() {
    return {
      newPosition: '',
      expand: false,
    }
  },

  computed: {
    ...mapState({
      orders: state => state.orders.requestList,
      loading: state => state.orders.loading,
      documentTemplates: state => state.document_templates.list
    })
  },
  methods: {

    loadOrders() {
      this.$store.dispatch(orderActions.getByRequestId, this.requestId)
    },

    create(){
      this.expand = true
      this.$store.dispatch(orderActions.create, {rid: this.requestId}).then(() => {
        this.loadOrders()
      })
    },
    clear() {
      this.$store.dispatch(orderActions.clear)
    }
  },
  mounted() {
    this.loadOrders()
  },
  watch: {
    requestId() {
      this.loadOrders()
    }
  },
  beforeDestroy() {
    this.clear()
  }

}
</script>

