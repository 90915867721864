<template>
  <div class="request" id="requestPage">
    <div class="client_area">
      <div class="box">
        <request-client :request-id="id" @updated="clientUpdated"></request-client>
      </div>
    </div>
    <div class="request_area">
      <div class="box">
        <div class="scroll_box">
          <request-header :request-id="id"></request-header>
          <request-calculations :request-id="id" @needUpdateOrders="updateOrders"></request-calculations>
          <request-orders :request-id="id"></request-orders>
          <request-payments :request-id="id"></request-payments>
          <request-assessments :request-id="id"></request-assessments>
          <request-mountings :request-id="id"></request-mountings>
          <request-files :request-id="id"></request-files>
        </div>
      </div>
    </div>
    <div class="history_area">
      <div class="box">
        <request-timeline :request-id="id"></request-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import price from "@/filters/price";
import qty from "@/filters/qty";
import role from "@/filters/role";

import RequestClient from "@/components/request/RequestClient";
import RequestHeader from "@/components/request/RequestHeader";
import RequestTimeline from "@/components/request/RequestTimeline";
import RequestCalculations from "@/components/request/RequestCalculations";
import RequestPayments from "@/components/request/RequestPayments";
import RequestMountings from "@/components/request/RequestMountings";
import RequestOrders from "@/components/request/RequestOrders";

import {actionTypes as orderActions} from "@/store/modules/orders";
import {actionTypes as timelineActions} from "@/store/modules/timeline";
import RequestAssessments from "@/components/request/RequestAssessments";
import RequestFiles from "@/components/request/RequestFiles";

export default {
  name: "Request",
  components: {
    RequestFiles,
    RequestAssessments,
    RequestMountings,
    RequestPayments,
    RequestCalculations,
    RequestTimeline,
    RequestHeader,
    RequestClient,
    RequestOrders
  },
  computed: {
    id() {
      return Number(this.$route.params.id)
    }
  },
  methods: {
    clientUpdated() {
      this.$store.dispatch(orderActions.getByRequestId, this.id)
      this.reloadTimeline()
    },
    updateOrders() {
      this.$store.dispatch(orderActions.getByRequestId, this.id)
    },
    reloadTimeline() {
      this.$store.dispatch(timelineActions.getByRequestId, this.id)
    },
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }

}
</script>
