<template>
  <div class="request_payments component-box" :class="{'mini': !expand}">
    <div class="component-box__header">
      <h2 @click="expand = !expand">Платежи ({{ payments.length }})</h2>
      <el-button size="mini" plain icon="el-icon-plus" @click="create">Новый платеж</el-button>
    </div>
    <div class="component-box__content" v-if="expand">
      <div class="list" v-if="payments.length">
        <div class="payment item" v-for="payment in payments">
          <div class="payment_box">
            <div class="title">
              Платеж №{{ payment.number }}
            </div>
            <div class="time">
              <i class="el-icon-time"></i> от {{ moment(payment.created_at).format('DD.MM.YYYY HH:mm') }}
            </div>
            <div class="money">
              <span class="price">Сумма: <strong>{{ payment.sum | price }}</strong> р</span>
            </div>
            <p class="comment" v-if="payment.purpose">Назначение: {{ payment.purpose }}</p>
            <p class="comment" v-if="payment.comment">Комментарий: {{ payment.comment }}</p>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <p>Нет данных для отображения</p>
      </div>
    </div>
    <el-dialog
        title="Новый платеж"
        :visible.sync="showCreator"
        width="400px"
        @close="closeCreator"
        :destroy-on-close="true">
      <div class="createPayment">
        <div class="form" v-if="orders.length">
          <div class="changeOrder" v-if="orders.length > 1 && newPayment.order == null">
            <label>По какому заказу принимаем платеж?</label>
            <div class="order" v-for="order in orders" @click="changePaymentOrder(order)">
              Заказ №{{ order.number }} на сумму {{ order.sum_wd |price }} <span class="rub">р</span>
            </div>
          </div>
          <div class="paymentForm" v-else>
            <template v-if="newPayment.order">
              <p class="order_info">Заказ №{{ newPayment.order.number }} от
                {{ moment(newPayment.order.created_at).format('DD.MM.YYYY HH:mm') }}</p>
              <span v-if="orderPayed > 0" class="money">
                Уже выплачено {{ orderPayed }}р, осталось {{ newPayment.order.sum_wd - orderPayed | price }}р
              </span>
              <span v-else class="money">
                Сумма к оплате {{ newPayment.order.sum_wd }}р
                <template v-if="newPayment.order.pre_pay"> / Предоплата {{ newPayment.order.pre_pay | price
                  }}р</template>
              </span>
              <div class="payment_types">
                <el-radio-group v-model="newPayment.payment_type_id" size="mini">
                  <el-radio-button :label="pt.id" v-for="pt in paymentTypes">{{ pt.title }}</el-radio-button>
                </el-radio-group>
              </div>
              <div class="sum">
                <label>Сумма платежа</label>
                <el-input-number v-model="newPayment.sum"
                                 size="mini"
                                 :precision="2"
                ></el-input-number>
              </div>
              <div class="form_element">
                <el-input
                    type="textarea"
                    v-model="newPayment.purpose"
                    placeholder="Назначение платежа"
                    size="mini"
                    :autosize="{ minRows: 2 }"
                ></el-input>
              </div>
              <div class="form_element">
                <el-input
                    type="textarea"
                    v-model="newPayment.comment"
                    placeholder="Комментарий"
                    size="mini"
                    :autosize="{ minRows: 2 }"
                ></el-input>
              </div>

            </template>
          </div>
        </div>
        <div class="empty" v-else>
          <p>В этом обращении нет ни одного заказа</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCreator">Отменить</el-button>
        <el-button type="primary" @click="createPayment" v-if="canAddPayment">Принять</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as paymentActions} from "@/store/modules/payments";
import {actionTypes as orderActions} from "@/store/modules/orders";

import price from "@/filters/price";

export default {
  name: "RequestPayments",
  props: {
    requestId: {
      type: Number,
      required: true
    }
  },
  filters: {
    price
  },
  data() {
    return {
      expand: false,
      showCreator: false,
      newPayment: {
        order: null,
        payment_type_id: null,
        sum: 0,
        purpose: '',
        comment: '',
      },
    }
  },
  computed: {
    ...mapState({
      payments: state => state.payments.requestList,
      orders: state => state.orders.requestList,
      paymentTypes: state => state.paymentTypes.list
    }),
    canAddPayment() {
      if (this.newPayment.order)
        return true
      return false;
    },
    orderPayed() {
      let payed = 0
      if (this.newPayment.order) {
        this.newPayment.order.payments.forEach((p) => {
          payed = payed + Number(p.sum)
        })
      }
      return payed.toFixed(2)
    }
  },


  methods: {
    createPayment() {
      this.$store.dispatch(paymentActions.savePayment, this.newPayment).then(() => {
        this.loadPayments()
        this.closeCreator()
        this.$store.dispatch(orderActions.getByRequestId, this.requestId)
      })
    },
    changePaymentOrder(order) {
      this.newPayment.order = order
      this.newPayment.purpose = 'Оплата по заказу №'+order.number
    },
    closeCreator() {
      this.showCreator = false
      this.newPayment = {
        order: null,
        payment_type_id: null,
        sum: 0,
        purpose: '',
        comment: '',
      }
    },
    loadPayments() {
      this.$store.dispatch(paymentActions.getRequestPayments, this.requestId)
    },
    create() {
      this.expand = true
      this.showCreator = true

      if (this.orders.length == 1) {
        this.newPayment.order = this.orders[0]
        this.newPayment.purpose = 'Оплата по заказу №'+this.orders[0].number
      }
    },

    clear() {
      this.$store.dispatch(paymentActions.clear)
    },
  },
  mounted() {
    this.loadPayments()
  },
  watch: {
    requestId() {
      this.loadPayments()
    }
  },
  beforeDestroy() {
    this.clear()
  }
}
</script>
