<template>
  <div class="request_header">
    <div class="box_header">
      <h2>Обращение <span v-if="request">№{{ request.id }}</span></h2>
      <div class="time" v-if="request">
        <i class="el-icon-time"></i>
        Создано {{ moment(request.created_at).format('DD.MM.YYYY HH:mm') }}<template v-if="request.updated_at">,
          последнее изменение - {{ moment(request.updated_at).format('DD.MM.YYYY HH:mm') }}
        </template>
      </div>
      <div class="time" v-else>
        <i class="el-icon-time"></i>
      </div>
      <div class="request_header__params" v-if="request">
        <div class="employee_selector">
          <label>Ответственный</label>
          <el-select
              v-model="request.employee_id"
              placeholder="Ответственный сотрудник"
              size="mini"
              @change="changeRequestEmployee"
              :loading="employeesIsLoading"
              v-if="this.$store.state.user.user.e.id === request.employee_id
                    || this.$store.state.user.user.role === 'admin'
                    || request.employee_id === null">
            <el-option
                v-for="employee in employeesForOwner"
                :key="employee.id"
                :label="employee.fio_full"
                :value="employee.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="status_selector">
          <label>Статус обращения</label>
          <el-select
              v-model="request.status_id"
              placeholder="Статус обращения"
              @change="changeRequestStatus"
              :loading="requestStatusesIsLoading"
              class="request_status_selector"
              size="mini"
              :style="{color: request.status.color, background: request.status.bg, borderRadius: '5px'}"
          >
            <el-option
                v-for="rstatus in requestStatuses"
                :key="rstatus.id"
                :label="rstatus.title"
                :value="rstatus.id"
                class="request_status_selector_item"
                v-if="requestStatuses">
                      <span class="status_in_select" :style="{
                          backgroundColor: rstatus.bg,
                          color: rstatus.color
                        }"> {{ rstatus.title }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="request_comment" v-if="request">

      <el-input
          type="textarea"
          v-if="request"
          v-model="request.comment"
          placeholder="Комментарий"
          size="mini"
          :autosize="{ minRows: 2 }"
          @change="changeRequestComment"
      ></el-input>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as requestsActions} from "@/store/modules/requests";

export default {
  name: "RequestHeader",
  props: {
    requestId: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapState({
      request: state => state.requests.current,
      loading: state => state.requests.currentLoading,
      employees: state => state.employees.list,
      employeesIsLoading: state => state.employees.loading,
      requestStatuses: state => state.statuses_for_requests.list,
      requestStatusesIsLoading: state => state.statuses_for_requests.loading,
    }),
    employeesForOwner() {
      return _.filter(this.employees, function (e) {
        if (e.user.role == 'admin' || e.user.role == 'manager') {
          if (e.first_name == null) e.first_name = '';
          if (e.last_name == null) e.last_name = '';
          if (e.middle_name == null) e.middle_name = '';
          e.full_name = e.last_name + ' ' + e.first_name + ' ' + e.middle_name
          return e
        }
      })
    }
  },
  methods: {
    loadRequest() {
      this.$store.dispatch(requestsActions.getCurrent, this.requestId)
    },
    changeRequestEmployee() {
      this.$store.dispatch(requestsActions.saveCurrent, {
        id: this.requestId,
        employee_id: this.request.employee_id
      })
    },
    changeRequestStatus() {
      this.$store.dispatch(requestsActions.saveCurrent, {
        id: this.requestId,
        status_id: this.request.status_id
      }).then(() => {
        this.loadRequest()
      })
    },
    changeRequestComment() {
      this.$store.dispatch(requestsActions.saveCurrent, {
        id: this.requestId,
        comment: this.request.comment
      })
    },
    clear(){
      this.$store.dispatch(requestsActions.clear)
    }
  },
  mounted() {
    this.loadRequest()
  },
  watch: {
    $route(to, from) {
      this.loadRequest()
    }
  },
  beforeDestroy() {
    this.clear()
  }
}
</script>
